<template>
  <y-card>

    <y-card-subtitle>
      Vos évènements
    </y-card-subtitle>

    <div class="text border-b">
      <ul
        v-if="countUserEvents"
        class="divide-y">
        <li
          v-for="(event, id) in getUserEvents"
          :key="id">
          <router-link
            class="p-4 pr-12 relative hover:bg-gray-50 group block"
            :to="{
              name: 'Event',
              params: { id },
            }">
            <p class="mb-1">
              {{ event.name }}
              <span class="text-xs text-gray-400">
                {{ event.code || id }}
              </span>
            </p>
            <p class="text-xs">
              <y-badge class="mr-1">
                {{ $filters.pluralize(countObj(event.players), 'joueur') }}
              </y-badge>
              <y-badge color="red-400">
                {{ $filters.pluralize(countObj(event.games), 'match') }}
              </y-badge>
            </p>

            <y-icon-chevron-right class="absolute h-5 right-4 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:translate-x-1 transition-transform" />
          </router-link>
        </li>
      </ul>
      <p
        class="p-4"
        v-else>
        Vous n'avez encore rejoint aucun évènement.
      </p>
    </div>
  </y-card>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

import YCard from '@/UI/YCard.vue';
import YCardSubtitle from '@/UI/YCardSubtitle.vue';
import YBadge from '@/UI/YBadge.vue';

import YIconChevronRight from '@/UI/icons/YIconChevronRight.vue';

export default {

  components: {
    YCard,
    YCardSubtitle,
    YBadge,
    YIconChevronRight,
  },

  setup() {
    const store = useStore();
    const getUserEvents = computed(() => store.getters.getUserEvents);

    const countUserEvents = computed(() => (getUserEvents.value ? Object.keys(getUserEvents.value)?.length : 0));
    const countObj = (obj) => (obj ? Object.keys(obj)?.length : 0);

    return {
      getUserEvents,
      countObj,
      countUserEvents,
    };
  },

};
</script>
